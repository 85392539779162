<template>
  <div class="details-form">
    <div class="details-form__header">
      <h4 v-if="newOrder">Новая заявка</h4>
      <h4 v-else-if="editOrder">Редактирование заявки</h4>
      <h4 v-else>Заявка {{ currentOrder['number'] }}</h4>

      <!-- Создание заявки -->
      <div v-if="newOrder" class="details-form__header-block tw-gap-x-3">
        <zem-link @click="cancelOrder">{{ $t('Cancel') }}</zem-link>
        <zem-button
          :loading="loadingCreateOrder"
          class="tw-gap-x-1.5"
          @click="validationForm('new-order', currentOrder['id'])"
        >
          <img v-if="!loadingCreateOrder" :src="require('@/assets/icons/check.svg')" alt="" />
          Сохранить
        </zem-button>
      </div>

      <!-- Редактирование заявки -->
      <div v-else-if="editOrder" class="details-form__header-block tw-gap-x-3">
        <zem-link @click="cancelOrder">{{ $t('Cancel') }}</zem-link>
        <zem-button
          v-if="$can('edit', 'partner-order-update')"
          :loading="loadingCreateOrder"
          class="tw-gap-x-1.5"
          @click="validationForm('read-order', currentOrder['id'])"
        >
          <img v-if="!loadingCreateOrder" :src="require('@/assets/icons/check.svg')" alt="" />
          {{ $t('Save') }}
        </zem-button>
      </div>

      <!-- Просмотр заявки -->
      <div v-else class="details-form__header-block tw-gap-x-3">
        <zem-link
          v-if="role === 'admin' || (isHuman && isStatusNew)"
          v-tippy
          :content="$t('Edit')"
          class="max-md:tw-hidden"
          @click="reOrder"
        >
          <edit3-icon size="17" />
        </zem-link>
        <zem-link
          v-if="role === 'admin' || (role !== 'admin' && isHuman && isStatusNew)"
          v-tippy
          :content="$t('Delete')"
          class="max-md:tw-hidden"
          @click="showDeleteModal = true"
        >
          <trash-icon size="16" />
        </zem-link>
        <zem-link
          v-if="!newOrder && isStatusActive && isHumanWinner"
          v-tippy
          class="max-md:tw-hidden"
          content="Редактировать факт. бюджет"
          @click="showEditBudgetFinish = true"
        >
          <edit3-icon size="16" />
        </zem-link>
        <zem-button class="md:tw-hidden" @click="reOrder">
          <zem-icon name="edit-o"></zem-icon>
        </zem-button>
        <zem-button class="md:tw-hidden" @click="reOrder">
          <zem-icon name="basket-white"></zem-icon>
        </zem-button>
        <zem-button class="md:tw-hidden" @click="cancelOrder">
          <zem-icon name="close-o"></zem-icon>
        </zem-button>
        <zem-link v-tippy class="max-md:tw-hidden" content="Отменить" @click="cancelOrder">
          <x-icon size="20" />
        </zem-link>
      </div>
    </div>
    <div class="details-form__container">
      <validation-observer ref="registerForm">
        <!-- Закрытие заявки -->
        <div v-if="!newOrder && isStatusActive && isHumanWinner" class="details-form__info-container mb-3">
          <div class="details-form__title-block">Реализована</div>
          <div class="details-form__data-block details-form__data-block__success">
            <zem-button @click="actionClosedOrder">
              <zem-icon class="cursor-pointer mr-2" name="check" size="12" />
              {{ $t('Yes') }}
            </zem-button>
            <zem-button class="ml-1" color="danger" @click="actionClosedNegativeOrder">
              <zem-icon class="cursor-pointer mr-2" name="close-o" size="14" />
              Нет
            </zem-button>
          </div>
        </div>

        <template v-if="!newOrder">
          <!-- Разместил -->
          <div class="details-form__info-container">
            <div class="details-form__title-block">Разместил</div>
            <div class="details-form__data-block">
              {{ currentOrder['createdBy'].data.human.data.fio }}
            </div>
          </div>

          <!-- Дата добавления -->
          <div class="details-form__info-container">
            <div class="details-form__title-block">Дата добавления</div>
            <div class="details-form__data-block">
              {{ formattedDate(currentOrder['created_at']) }}
            </div>
          </div>

          <!-- Статус в кабинете -->
          <div class="details-form__info-container">
            <div class="details-form__title-block">Статус в кабинете</div>
            <div class="details-form__data-block">
              {{ currentOrder['status'].data.title }}
            </div>
          </div>

          <!-- Статус у партнера -->
          <div class="details-form__info-container">
            <div class="details-form__title-block">Статус у партнера</div>
            <div class="details-form__data-block">
              {{ currentOrder['partner_status'].data.title }}
            </div>
          </div>
        </template>

        <!-- Бюджет -->
        <div class="details-form__info-container" v-if="!newOrder">
          <div class="details-form__title-block">Номер в AmoCRM</div>
          <div v-if="!newOrder && !editOrder" class="details-form__data-block">
            <a :href="currentOrder['amocrm_lead_url']" class="text-primary hover:tw-underline" target="_blank">{{
              currentOrder['amocrm_lead_id']
            }}</a>
          </div>
          <div v-else class="details-form__data-block m-0">
            <ZemInput v-model="currentOrder['amocrm_lead_id']" class="m-0" integer placeholder="Бюджет" type="text" />
          </div>
        </div>

        <!-- Взял/Разместил -->
        <div class="details-form__info-container">
          <div class="details-form__title-block">
            {{ !newOrder ? (!editOrder ? 'Взято' : 'Взять от') : 'Разместить от' }}
          </div>
          <div v-if="!editOrder && !newOrder" class="details-form__data-block">
            {{ currentOrder['winner_by'] ? currentOrder['winnerBy'].data.human.data.fio : '' }}
          </div>
          <div
            v-else-if="!newOrder && !editOrder && !isHuman && !isHumanWinner"
            class="details-form__data-block text-muted"
          >
            Скрыто
          </div>
          <div v-else-if="$store.state.user.profile.human.data.length === 0" class="details-form__data-block">
            <ZemDropdownList
              :default="'Организация'"
              :options="[]"
              :value="userOrganizations"
              class="select mb-0"
              disabled
            />
          </div>
          <div v-else class="details-form__data-block m-0">
            <ZemDropdownList
              :default="'Организация'"
              :disabled="userOrganizations.length < 2"
              :options="userOrganizations"
              :value="organization"
              class="select mb-0"
              item="name"
              @input="organization = $event"
            />
          </div>
        </div>

        <!-- Бюджет -->
        <div class="details-form__info-container">
          <div class="details-form__title-block">Бюджет</div>
          <div v-if="!newOrder && !editOrder" class="details-form__data-block">
            {{ currentOrder['budget_start']['currency'].text }}
          </div>
          <div v-else class="details-form__data-block m-0">
            <ZemInput v-model="budget" class="m-0" integer placeholder="Бюджет" type="text" />
          </div>
        </div>

        <!-- Факт. бюджет -->
        <div
          v-if="(statusOrder === 'Архивный' || statusOrder === 'Активный') && !showEditBudgetFinish"
          class="details-form__info-container"
        >
          <div class="details-form__title-block">Факт. бюджет</div>
          <div v-if="!newOrder && !editOrder" class="details-form__data-block">
            {{ currentOrder['budget_finish']['currency'].text }}
          </div>
          <div v-else class="details-form__data-block m-0">
            <ZemInput v-model="currentBudget" class="m-0" integer placeholder="Факт. бюджет" type="text" />
          </div>
        </div>

        <!-- Редактирование факт. бюджета -->
        <div v-if="showEditBudgetFinish" class="details-form__info-container">
          <div class="details-form__title-block">Факт. бюджет</div>
          <div class="details-form__data-block d-flex m-0">
            <ZemInput v-model="currentBudget" class="m-0 w-unset" integer placeholder="Факт. бюджет" type="text" />
            <zem-button class="ml-1" @click="updateBudgetOrder(currentOrder['id'])">
              <zem-icon class="cursor-pointer" name="check" size="12" />
            </zem-button>
            <zem-button class="ml-1" @click="showEditBudgetFinish = false">
              <zem-icon class="cursor-pointer" name="close-o" size="13" />
            </zem-button>
          </div>
        </div>

        <!-- Регион -->
        <div class="details-form__info-container">
          <div class="details-form__title-block">Регион</div>
          <div v-if="!newOrder && !editOrder" class="details-form__data-block">
            {{ currentOrder['region'].data.name }}
          </div>
          <div v-else class="details-form__data-block m-0">
            <ZemDropdownList
              v-model="region"
              :options="$store.state.other['regions']"
              class="select mb-0"
              placeholder="Регион"
            />
          </div>
        </div>

        <!-- Имя -->
        <div class="details-form__info-container">
          <div :class="{'required-field': editOrder || newOrder}" class="details-form__title-block">Имя</div>
          <div v-if="!newOrder && !editOrder" class="details-form__data-block">
            {{ currentOrder['client_name'] }}
          </div>
          <div v-else class="details-form__data-block m-0">
            <ValidationProvider v-slot="{errors}" :rules="{required: true}" name="Имя">
              <ZemInput
                v-model="name"
                :errors="errorsBackend"
                class="m-0"
                error-title="client_name"
                placeholder="Имя"
                type="text"
                @input="errorsBackend = []"
              />
              <p v-show="errors.length !== 0" class="details-form__data-block__error">
                {{ errors[0] }}
              </p>
            </ValidationProvider>
          </div>
        </div>

        <!-- Телефон -->
        <div class="details-form__info-container">
          <div class="details-form__title-block">
            Телефон<span v-if="editOrder || newOrder" class="required-field">*</span>
          </div>
          <div v-if="(!newOrder && !editOrder && isHuman) || isHumanWinner" class="details-form__data-block">
            {{ formattedPhone(currentOrder['client_phone']) }}
          </div>
          <div v-else-if="!newOrder && !editOrder && isAdmin" class="details-form__data-block">
            {{ formattedPhone(currentOrder['client_phone']) }}
          </div>
          <div v-else-if="!newOrder && !isAdmin && !isHuman" class="details-form__data-block text-muted">Скрыто</div>
          <div v-else class="details-form__data-block m-0">
            <ValidationProvider
              v-slot="{errors}"
              :rules="{
                required: true,
                regex: `^[\\+]?[7]?[8]?\\s[(][0-9]{3}[)]\\s[0-9]{3}[-\\s\\.]?[0-9]{2}[-\\s\\.]?[0-9]{2}$`,
              }"
              name="Телефон"
            >
              <ZemInput
                v-model="phone"
                :errors="errorsBackend"
                class="m-0"
                error-title="client_phone"
                mask="phone"
                placeholder="+71234567890"
                type="tel"
                @input="errorsBackend = []"
                @update-phone="phone = $event"
              />
              <p v-show="errors.length !== 0" class="details-form__data-block__error">
                {{ errors[0] }}
              </p>
            </ValidationProvider>
          </div>
        </div>

        <!-- Почта -->
        <div class="details-form__info-container">
          <div class="details-form__title-block">Почта</div>
          <div v-if="(!newOrder && !editOrder && isHuman) || isHumanWinner" class="details-form__data-block">
            {{ currentOrder['client_email'] }}
          </div>
          <div v-else-if="!newOrder && !editOrder && isAdmin" class="details-form__data-block">
            {{ currentOrder['client_email'] }}
          </div>
          <div
            v-else-if="!newOrder && !isAdmin && !isHuman && !isHumanWinner"
            class="details-form__data-block text-muted"
          >
            Скрыто
          </div>
          <div v-else class="details-form__data-block">
            <ValidationProvider v-slot="{errors}" :rules="{required: false, email: true}" name="Почта">
              <ZemInput v-model="email" placeholder="mail@mail.ru" type="text" />
              <p v-show="errors.length !== 0" class="details-form__data-block__error">
                {{ errors[0] }}
              </p>
            </ValidationProvider>
          </div>
        </div>

        <!-- Комментарий -->
        <div class="details-form__info-container">
          <div class="details-form__title-block">
            Комментарий<span v-if="editOrder || newOrder" class="required-field">*</span>
          </div>
          <div v-if="!newOrder && !editOrder" class="details-form__data-block">
            {{ currentOrder['comment'] }}
          </div>
          <div v-else class="details-form__data-block">
            <ValidationProvider
              v-slot="{errors}"
              :rules="{required: true}"
              class="details-form__data-block_font_0"
              name="Комментарий"
              tag="div"
            >
              <ZemTextarea v-model="comment" placeholder="Комментарий" type="textarea" />
              <p v-if="errors.length !== 0" class="details-form__data-block__error">
                {{ errors[0] }}
              </p>
            </ValidationProvider>
          </div>
        </div>

        <!-- Адрес -->
        <div class="details-form__info-container">
          <div class="details-form__title-block">Адрес</div>
          <div v-if="!newOrder && !editOrder" class="details-form__data-block">
            {{ currentOrder['address'] }}
            <!--                        <zem-button class="mt-1" @click="showMap = !showMap">Карта</zem-button>-->
          </div>
          <div v-else class="details-form__data-block">
            <ValidationProvider v-slot="{errors}" :rules="{required: false}" name="Адрес" tag="div">
              <div class="d-flex align-center justify-content-center">
                <ZemTextarea v-model="address" placeholder="Адрес" type="text" @input="getAddresses" />
              </div>
              <p v-show="errors.length !== 0" class="details-form__data-block__error">
                {{ errors[0] }}
              </p>
              <zem-list-group v-show="showAddressList" class="mt-1" max-height>
                <zem-list-group-item
                  v-for="(item, index) in $store.state.exchange.address"
                  :key="index"
                  class="cursor-pointer py-25"
                  @click="selectAddress(item)"
                  >{{ item.value }}
                </zem-list-group-item>
              </zem-list-group>
            </ValidationProvider>
          </div>
        </div>

        <!-- Координаты -->
        <div class="details-form__info-container">
          <div class="details-form__title-block">Координаты</div>
          <ZemLink v-if="!newOrder && !editOrder" class="details-form__data-block" @click="redirectMaps">
            {{
              currentOrder['location_lat'] !== ''
                ? currentOrder['location_lat'] + ', ' + currentOrder['location_long']
                : ''
            }}
          </ZemLink>
          <div v-else class="details-form__data-block m-0">
            <ValidationProvider
              v-slot="{errors}"
              :rules="{
                required: false,
                regex: `^[\\-]?[0-9]{2}\\.[0-9]{6}\\,\\s[\\-]?[0-9]{2}\\.[0-9]{6}$`,
              }"
              name="Координаты"
            >
              <ZemInput v-model="textCoords" class="m-0" placeholder="12.345678, 34.567890" type="text" />
              <p v-show="errors.length !== 0" class="details-form__data-block__error">
                {{ errors[0] }}
              </p>
            </ValidationProvider>
            <!--                        <ZemInput-->
            <!--                            :value="coords[1]"-->
            <!--                            class="m-0"-->
            <!--                            placeholder="Долгота"-->
            <!--                            @input="location_long = $event"-->
            <!--                        >-->
            <!--                            Долгота-->
            <!--                        </ZemInput>-->
          </div>
        </div>
      </validation-observer>

      <yandex-map
        v-if="showMap"
        :controls="['zoomControl', 'geolocationControl']"
        :coords="coords"
        ymap-class="custom-map"
        zoom="10"
        @click="onClick"
      >
        <ymap-marker :coords="coords" marker-id="1" marker-type="placemark" />
      </yandex-map>
    </div>

    <ZemModal
      :loading-success="loadingDeleteOrder"
      :show="showDeleteModal"
      :title-cancel="$t('Cancel') || ''"
      :title-success="$t('Yes') || ''"
      title="Уверены, что хотите удалить?"
      @on-change="showDeleteModal = !showDeleteModal"
      @on-success="deleteOrder(currentOrder['id'])"
      @on-cancel="showDeleteModal = false"
    />

    <ZemModal
      :loading-success="loadingClosedOrder"
      :show="showClosedOrderModal"
      :title="
        statusClosedOrder === '1'
          ? `Вы действительно хотите завершить заявку как успешную?`
          : `Вы действительно хотите завершить заявку как <b style='color: #ff4d4f;'>закрытую и нереализованную</b>?`
      "
      :title-cancel="$t('Cancel') || ''"
      color-cancel="grey"
      title-success="Завершить"
      @on-change="showClosedOrderModal = !showClosedOrderModal"
      @on-success="handlerClosedOrder"
      @on-cancel="showClosedOrderModal = false"
    >
      <ZemCollapse class="mt-3" is-opened-default title="Информация по заявке" title-not-bold>
        <div class="zem-collapse-table">
          <div v-for="(field, f_idx) in fields" :key="f_idx" class="zem-collapse-table__row">
            <div class="zem-collapse-table__column title">
              {{ field.name }}
            </div>
            <div class="zem-collapse-table__column text">
              {{ field.value }}
            </div>
          </div>
        </div>
      </ZemCollapse>
      <ZemInput
        v-if="statusClosedOrder === '1'"
        v-model="currentBudget"
        class="mt-1"
        integer
        title="Факт. бюджет"
        type="text"
      />
    </ZemModal>
  </div>
</template>

<script>
import ZemButton from './ui/ZemButton'
import ZemInput from './ui/ZemInput'
import ZemDropdownList from './ui/ZemDropdownList'
import {errorHandler, formattedDate, formattedPhone, phoneTrim} from '@/assets/scripts/scripts'
import ZemTextarea from '@/components/ui/Textarea'
import ZemLink from '@/components/ui/ZemLink'
import ZemListGroup from '@/components/ui/ListGroup'
import ZemListGroupItem from '@/components/ui/ListGroupItem'
import ExchangeService from '@/services/exchange.service'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {yandexMap, ymapMarker} from 'vue-yandex-maps'
import $http from '@/helpers/config-axios'
import messages from '@/helpers/messages'
import ZemIcon from '@/components/ui/ZemIcon'
import ZemPreloader from '@/components/ui/ZemPreloader'
import ZemModal from '@/components/ui/ZemModal'
import ZemCollapse from '@/components/ui/ZemCollapse'
import ToastCreatedOrder from '@/components/ToastCreatedOrder'
import {CheckIcon, Edit3Icon, TrashIcon, XIcon} from 'vue-feather-icons'
import PreloaderWrapper from '@/components/PreloaderWrapper.vue'

export default {
  name: 'ExchangeDetails',

  components: {
    PreloaderWrapper,
    ZemPreloader,
    ZemTextarea,
    ZemButton,
    ZemInput,
    ZemLink,
    ZemDropdownList,
    ZemListGroup,
    ZemListGroupItem,
    ZemIcon,
    ValidationProvider,
    ValidationObserver,
    ZemModal,
    ZemCollapse,

    ToastCreatedOrder,

    yandexMap,
    ymapMarker,

    Edit3Icon,
    TrashIcon,
    XIcon,
    CheckIcon,
  },

  props: {
    // currentOrder: {
    //   type: Object,
    //   default: () => ({}),
    // },
    newOrder: {
      type: Boolean,
      default: false,
    },
    editOrder: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentOrder: {},
      textCoords: '',
      numberModel: '',
      query: '',
      token: process.env.VITE_APP_DADATA_API_KEY,
      showMap: false,
      showAddressList: false,
      settings: {
        apiKey: '6f93606d-8948-4c52-8db6-363d427c0571',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1',
      },
      coords: ['54.828966', '39.831893'],
      region: {
        id: 'NxOpZowo9GmjKqdR',
        title: 'Москва и МО',
      },
      organization: {
        title: '',
      },
      name: '',
      phone: '',
      comment: '',
      email: '',
      address: '',
      location_lat: '',
      location_long: '',
      budget: '',
      currentBudget: '',
      rate: '',
      timer: null,
      exchangeStatusValues: ['Активный', 'Архивный', 'Новый'],
      partnersStatusValues: ['Успешно реализован', 'Назначить замер', 'Выполнить работы'],
      status: [false, false, false],
      errorsBackend: [],
      statusClosedOrder: '',
      loadingBackActive: false,
      showDeleteModal: false,
      showClosedOrderModal: false,
      showEditBudgetFinish: false,
      loadingDeleteOrder: false,
      loadingClosedOrder: false,
      loadingClosedNegativeOrder: false,
      loadingCreateOrder: false,
    }
  },

  computed: {
    role() {
      return this.$store.state.user.role
    },
    profile() {
      return this.$store.state.user.profile
    },
    isAdmin() {
      return this.role === 'admin'
    },
    isHuman() {
      return this.currentOrder['created_by'] === this.profile.human.data.id
    },
    isHumanWinner() {
      return this.currentOrder['winner_by'] === this.profile.human.data.id
    },
    isStatusNew() {
      return this.currentOrder.status.data.title === 'Новый'
    },
    isStatusActive() {
      return Array.isArray(this.currentOrder.status.data) ? false : this.currentOrder.status.data.title === 'Активный'
    },
    userOrganizations() {
      if (this.$store.state.user.profile.human.data.length !== 0) {
        return this.$store.state.user.profile.human.data.organizations.data
      } else {
        return this.$store.state.user.profile.name
      }
    },
    logicShowActiveButton() {
      if (!this.newOrder) {
        if (this.profile.human.data.length > 0 && this.profile.human.data.organizations.data.length > 0) {
          return (
            (this.role === 'admin' && this.isStatusNew) ||
            (!this.isHuman &&
              this.currentOrder.organization_id !== this.profile.human.data.organizations.data[0].id &&
              this.isStatusNew)
          )
        } else {
          return this.role === 'admin' || (this.isHuman && this.isStatusNew)
        }
      } else {
        return false
      }
    },
    statusOrder() {
      return Object.keys(this.currentOrder).length === 0 ? '' : this.currentOrder.status.data.title
    },
    fields() {
      if (Object.keys(this.currentOrder).length === 0) {
        return []
      } else {
        return [
          {
            name: 'Дата добавления',
            value: formattedDate(this.currentOrder.created_at),
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Статус в кабинете',
            value: this.currentOrder.status.data.title,
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Статус у партнера',
            value: this.currentOrder['partner_status'].data.title,
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Бюджет',
            value: this.currentOrder.budget_start.currency.text,
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Регион',
            value: this.currentOrder.region.data.name,
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Имя',
            value: this.currentOrder.client_name,
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Телефон',
            value: formattedPhone(this.currentOrder.client_phone),
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Почта',
            value: this.currentOrder.client_email,
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Комментарий',
            value: this.currentOrder.comment,
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Адрес',
            value: this.currentOrder.address,
            type: 'text',
            new: false,
            read: false,
          },
          {
            name: 'Координаты',
            value:
              this.currentOrder.location_lat !== ''
                ? this.currentOrder.location_lat + ', ' + this.currentOrder.location_long
                : '',
            type: 'text',
            new: false,
            read: false,
          },
        ]
      }
    },
  },

  watch: {
    showEditBudgetFinish() {
      if (this.showEditBudgetFinish) this.currentBudget = this.currentOrder.budget_finish
    },
    currentOrder() {
      this.coords = [this.currentOrder.location_lat, this.currentOrder.location_long]
    },
    editOrder() {
      if (this.editOrder) {
        this.address = this.currentOrder.address
        this.email = this.currentOrder.client_email
        this.name = this.currentOrder.client_name
        this.phone = formattedPhone(this.currentOrder.client_phone)
        this.comment = this.currentOrder.comment
        this.region = {id: this.currentOrder.region.data.id, title: this.currentOrder.region.data.name}
        if (this.currentOrder.location_lat === '') {
          this.textCoords = ''
        } else {
          this.textCoords = this.currentOrder.location_lat + ', ' + this.currentOrder.location_long
        }
        this.budget = this.currentOrder['budget_start']['currency'].value
        this.rate = this.currentOrder.rate
        this.showMap = false
      }
    },
    newOrder() {
      if (this.newOrder) {
        this.clearData()
      }
    },
    budget() {
      this.rate = Math.ceil(Number(String(this.budget * 0.1)))
    },
  },

  mounted() {
    if (this.userOrganizations.length === 0) {
      this.organization = {
        title: this.profile.name,
      }
    } else {
      this.organization = this.userOrganizations[0]
    }
  },

  methods: {
    formattedPhone,
    formattedDate,
    getPartnerOrder() {
      this.$store.commit('other/onChangeLoadingItem', true)
      const {id} = this.$route.params
      ExchangeService.getPartnerOrder(id)
        .then(r => {
          const order = r.data.data
          this.currentOrder = order
          this.$store.commit('other/onChangeLoadingItem', false)
          this.address = order.address
          this.email = order.client_email
          this.name = order.client_name
          this.phone = formattedPhone(order.client_phone)
          this.comment = order.comment
          this.region = {id: order.region.data.id, title: order.region.data.name}
          if (order.location_lat === '') {
            this.textCoords = ''
          } else {
            this.textCoords = order.location_lat + ', ' + order.location_long
          }
          this.budget = order['budget_start']['currency'].value
          this.rate = order.rate
          this.showMap = false
        })
        .catch(e => {
          if (e.response.status === 422 && this.$route.name !== 'exchange') this.$router.push({name: 'exchange'})
          this.closeRightSidebar()
        })
    },
    getAddresses() {
      clearTimeout(this.timer)
      this.timer = null
      this.timer = setTimeout(() => {
        if (!this.showAddressList) this.showAddressList = true
        ExchangeService.getDaDataAddress(this.address)
      }, 500)
    },
    redirectMaps() {
      let link = document.createElement('a')
      link.href = `https://yandex.ru/maps/?ll=${this.currentOrder.location_long}%2C${this.currentOrder.location_lat}&mode=whatshere&whatshere%5Bpoint%5D=${this.currentOrder.location_long}%2C${this.currentOrder.location_lat}&whatshere%5Bzoom%5D=15.19&z=14.19`
      link.target = '_blank'
      link.click()
    },
    reOrder() {
      this.editOrder = true
    },
    onGetApplication() {
      this.$store.commit('exchange/changeTakeInWorkPreloader', true)
      this.$emit('getApplication', this.currentOrder)
    },
    createOrder() {
      this.loadingCreateOrder = true
      let data = {
        client_name: this.name,
        client_phone: phoneTrim(this.phone),
        comment: this.comment,
        location_lat: Number(this.textCoords.split(', ')[0]),
        location_long: Number(this.textCoords.split(', ')[1]),
        budget_start: Number(this.budget),
        // rate: this.rate,
        region_id: this.region.value,
      }
      if (this.$store.state.user.profile.human.data.length !== 0) data['organization_id'] = this.organization.id
      if (this.address !== '') data['address'] = this.address
      if (this.email !== '') data['client_email'] = this.email

      ExchangeService.checkSuccessPhone(phoneTrim(this.phone)).then(r => {
        if (r.data.data.length > 0) {
          const name = r.data.data[0].human.data.fio
          const organization = r.data.data[0].organization_id ? ' (' + r.data.data[0].organization.data.title + ')' : ''

          const content = {
            component: ToastCreatedOrder,
            props: {
              createData: data,
              title: `Пользователь ${name}${organization} уже создал заявку с таким номером телефона! Продолжить?`,
            },
            listeners: {
              click: () => {
                ExchangeService.createOrder(data)
                  .then(() => {
                    ExchangeService.getAllOrders()
                    this.$toast.success(messages.createOrder)
                    this.$store.commit('sidebars/changeRightSidebar', !this.$store.state.sidebars.rightSidebar)
                    this.clearData()
                  })
                  .catch(e => {
                    errorHandler(e)
                  })
              },
            },
          }

          this.$toast.info(content, {
            timeout: 5000,
            hideProgressBar: true,
            closeButton: 'button',
          })

          this.loadingCreateOrder = false
        } else {
          ExchangeService.createOrder(data)
            .then(() => {
              ExchangeService.getAllOrders()
              this.loadingCreateOrder = false
              this.$toast.success(messages.createOrder)
              this.$store.commit('sidebars/changeRightSidebar', !this.$store.state.sidebars.rightSidebar)
              this.clearData()
            })
            .catch(e => {
              this.loadingCreateOrder = false
              this.errorsBackend = errorHandler(e)
            })
        }
      })
    },
    updateOrder(id) {
      this.loadingCreateOrder = true
      ExchangeService.updateOrder(
        id,
        {
          address: this.address,
          client_email: this.email,
          client_name: this.name,
          client_phone: phoneTrim(this.phone),
          comment: this.comment,
          location_lat: Number(this.textCoords.split(', ')[0]),
          location_long: Number(this.textCoords.split(', ')[1]),
          budget_start: Number(this.budget),
          rate: this.rate,
          amocrm_lead_id: this.currentOrder['amocrm_lead_id'],
        },
        true
      )
        .then(() => {
          ExchangeService.getAllOrders()
          this.loadingCreateOrder = false
          this.$toast.success(messages.editOrder)
          this.$store.commit('sidebars/changeRightSidebar', !this.$store.state.sidebars.rightSidebar)
        })
        .catch(e => {
          this.errorsBackend = errorHandler(e)
          this.loadingCreateOrder = false
        })
    },
    updateBudgetOrder(id) {
      ExchangeService.updateBudgetFinish(
        {
          budget_finish: Number(this.currentBudget),
        },
        id
      )
        .then(() => {
          ExchangeService.getAllOrders()
          this.$toast.success(messages.editBudgetFinish)
          this.showEditBudgetFinish = false
        })
        .catch(e => {
          this.errorsBackend = errorHandler(e)
        })
    },
    deleteOrder(id) {
      this.loadingDeleteOrder = true
      this.showDeleteModal = false
      ExchangeService.deleteOrder(id)
        .then(() => {
          ExchangeService.getAllOrders()
          this.$toast.success(messages.deleteOrder)
          this.loadingDeleteOrder = false
          this.$router.push({name: 'exchange'})
        })
        .catch(e => {
          this.errorsBackend = errorHandler(e)
          this.loadingDeleteOrder = false
        })
      this.$store.commit('sidebars/changeRightSidebar', !this.$store.state.sidebars.rightSidebar)
    },
    cancelOrder() {
      this.$store.commit('sidebars/changeRightSidebar', false)
    },
    validationForm(type, id = null) {
      this.$refs.registerForm.validate().then(success => {
        if (success && type === 'new-order') {
          this.createOrder()
        } else if (success && type === 'read-order') {
          if (this.$store.state.user.role === 'admin') {
            this.updateOrder(id)
          } else {
            ExchangeService.checkStatusOrder(id).then(r => {
              if (r.data.data.status.data.title === 'Новый') {
                this.updateOrder(id)
              } else {
                this.$toast.error(messages.errorActiveEditOrder)
              }
            })
          }
        } else {
          this.$toast.error(messages.errorValidForm)
        }
      })
    },
    onClick(e) {
      this.coords = [Number(e.get('coords')[0]).toFixed(6), Number(e.get('coords')[1]).toFixed(6)]
      this.getAddress()
    },
    selectAddress(el) {
      this.showAddressList = false
      this.address = el.value
      this.coords = [Number(el.data['geo_lat']), Number(el.data['geo_lon'])]
    },
    getAddress() {
      let url = process.env.VUE_APP_DADATA_URL
      let token = process.env.VUE_APP_DADATA_API_KEY
      let query = {
        lat: this.coords[0],
        lon: this.coords[1],
        radius_meters: 10,
      }

      let options = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
      }

      $http()
        .post(url, query, options)
        .then(response => {
          this.address = response.data.suggestions[0].value
        })
    },
    actionClosedOrder() {
      this.showClosedOrderModal = true
      this.statusClosedOrder = '1'
    },
    actionClosedNegativeOrder() {
      this.showClosedOrderModal = true
      this.statusClosedOrder = '2'
    },
    closeOrder() {
      this.loadingClosedOrder = true
      if (this.currentBudget !== '') {
        ExchangeService.changePartnerStatusOrder(
          {
            orders: [
              {
                id: this.currentOrder.id,
                budget_finish: this.currentBudget,
              },
            ],
          },
          'aYOxlpzRMwrX3gD7'
        )
          .then(() => {
            this.$toast.success(messages.closeOrders)
            this.$store.commit('sidebars/changeRightSidebar', false)
            ExchangeService.getAllOrders()
            this.showClosedOrderModal = false
            this.loadingClosedOrder = false
          })
          .catch(e => {
            errorHandler(e)
          })
      } else {
        this.loadingClosedOrder = false
        this.$toast.error(messages.errorCloseOrder)
      }
    },
    closeNegativeOrder() {
      this.loadingClosedOrder = true
      ExchangeService.changePartnerStatusOrder(
        {
          orders: [
            {
              id: this.currentOrder.id,
            },
          ],
        },
        '39n0Z12OZGKERJgW'
      )
        .then(() => {
          this.$toast.success(messages.closeNegativeOrders)
          this.$store.commit('sidebars/changeRightSidebar', false)
          ExchangeService.getAllOrders()
          this.showClosedOrderModal = false
          this.loadingClosedOrder = false
        })
        .catch(e => {
          errorHandler(e)
        })
    },
    handlerClosedOrder() {
      this.statusClosedOrder === '1' ? this.closeOrder() : this.closeNegativeOrder()
    },
    clearData() {
      this.region = {
        id: 'NxOpZowo9GmjKqdR',
        title: 'Москва и МО',
      }
      this.address = ''
      this.email = ''
      this.name = ''
      this.phone = ''
      this.comment = ''
      this.textCoords = ''
      this.budget = ''
      this.currentBudget = ''
      this.rate = ''
      this.showMap = false
    },
  },
}
</script>

<style lang="scss">
.custom-map {
  height: 250px;
  margin-top: 10px;
}

.details-form__info-container {
  .zem-input {
    margin-top: 0;
  }
}
</style>

<style lang="scss" scoped>
.details-form {
  border: 1px solid $color-catskill-grey;
  border-radius: 4px;
  margin: 20px;
  padding: 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 26px;

    &-block {
      display: flex;
      align-items: center;
    }

    h4 {
      font-weight: 700;
      font-size: 17px;
      line-height: 20px;
      color: $color-mine-shaft;
      margin: 0;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }

  &__title-block {
    font-weight: 400;
    font-size: 12px;
    // line-height: 140%;
    color: $color-gull-gray;
    margin: 0px 12px;
    margin-left: 0;
    flex: 1;
  }

  &__data-block {
    font-weight: 400;
    font-size: 12px;
    // line-height: 140%;
    color: $color-mine-shaft;
    flex: 1;

    &_font_0 {
      font-size: 0;
    }

    &_margin_top {
      margin-top: 8px;
    }

    &__success {
      display: flex;
    }
  }

  &__info-container {
    display: flex;
    align-items: center;
    align-content: flex-start;
    text-align: left;
    margin-top: 8px;

    .zem-dropdown {
      margin-top: 0;
    }

    .zem-textarea {
      margin-top: 0;
    }
  }

  &__btn-block {
    width: 140px;
  }
}

.zem-input-container {
  input {
    margin-top: 0;
  }
}

@media screen and (max-width: 1024px) {
  .details-form {
    width: 100%;
    margin: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;

    &__container {
      overflow-x: auto;
    }
  }
}
</style>