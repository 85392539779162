<template>
  <div>
    <SidebarLeft :class="{'sidebar-left-show': $store.state.sidebars.leftSidebar}">
      <FilterEstimatesForm ref="filter" @update-data="getEstimates(true, true)" />
    </SidebarLeft>
    <TableContainer
      ref="table-container"
      title-add-button="Смета"
      :columns="tableData"
      :counter-filter="$store.state.estimates.searchCount"
      :is-loading-items="isLoadingItems"
      :load-more="loadMoreData"
      :rows="estimates"
      :sorting-fields="sortingFields"
      :title="$t('Estimates') || ''"
      :show-add-button="false"
      :is-archive="$can('edit', 'estimate-read-archive')"
      :is-archiving="$can('edit', 'estimate-trash')"
      :is-remove-from-archive="$can('edit', 'estimate-delete')"
      name="estimates"
      @show-right-sidebar="addEstimate"
      @update-data="$refs.filter.getFilter()"
      @next-page="getEstimates(false)"
      @archive-items="archiveItems"
      @delete-item="deleteEstimates"
      @return-from-archive="returnFromArchive"
    >
      <template v-slot:head-buttons-after>
        <zem-link
          v-if="quantityItems.length === 1 && $can('edit', 'estimate-create')"
          :disabled="isLoadingCopyItem"
          @click="copyEstimate"
        >
          <copy-icon v-if="!isLoadingCopyItem" size="14" />
          <zem-preloader v-else class="mr-0" color="primary" size="14" />
          <span class="mobile-d-none ml-1">Создать версию</span>
        </zem-link>
        <ZemButton
          v-if="$can('edit', 'estimate-create')"
          :disabled="isLoadingItem"
          :loading="isLoadingItem"
          icon
          @click="addEstimate"
        >
          <img :src="require('@/assets/icons/plus.svg')" alt="" class="tw-mb-[1px]" v-if="!isLoadingItem" />
          <span class="mobile-d-none">Смета</span>
        </ZemButton>
      </template>
    </TableContainer>
    <SidebarRight
      :class="{
        'sidebar-right-show': $store.state.sidebars.rightSidebar,
      }"
      sidebar-name="rightSidebar"
    >
      <!--      <EstimateCreate/>-->
    </SidebarRight>
  </div>
</template>

<script>
import TableContainer from '@/components/TableContainer.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import {CopyIcon, PlusIcon, Trash2Icon} from 'vue-feather-icons'
import EstimatesService from '@/services/estimates.service'
import ZemLink from '@/components/ui/ZemLink.vue'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import ZemTable from '@/components/ui/ZemTable.vue'
import SidebarLeft from '@/components/SidebarLeft.vue'
import FilterEstimatesForm from '@/components/FilterEstimatesForm.vue'
import DetailsFilter from '@/components/DetailsFilter.vue'
import SidebarRight from '@/components/SidebarRight.vue'
import ExchangeDetails from '@/components/ExchangeDetails.vue'
import EstimateCreate from '@/components/EstimateCreate.vue'
import store from '@/store'

export default {
  components: {
    EstimateCreate,
    ExchangeDetails,
    SidebarRight,
    DetailsFilter,
    FilterEstimatesForm,
    SidebarLeft,
    ZemTable,
    ZemPreloader,
    ZemLink,
    ZemButton,
    TableContainer,
    PlusIcon,
    CopyIcon,
    Trash2Icon,
  },
  data() {
    return {
      tableData: [],
      estimates: [],
      nextPage: 1,
      sortingFields: ['amocrm_lead_id', 'title', 'version', 'created_at', 'created_by', 'status_id'],
      isLoadingItems: false,
      isLoadingItem: false,
      isLoadingCopyItem: false,
      isLoadingDeleteItem: false,
      loadMoreData: {
        enable: true,
        showButton: false,
        loading: false,
      },
      pageElements: 20,
    }
  },
  created() {
    this.tableData = [
      {
        label: 'id сделки АМО',
        field: 'amocrm_lead_id',
        sortField: 'amocrm_lead_id',
        type: 'amocrm_lead_id',
        showInTable: true,
        sort: 0,
      },
      {
        label: 'Номер заказа',
        field: 'title',
        sortField: 'title',
        showInTable: true,
        type: 'estimate_title',
        sort: 0,
      },
      {
        label: 'Версия сметы',
        field: 'version',
        sortField: 'version',
        showInTable: true,
        sort: 0,
      },
      {
        label: 'Дата сметы',
        field: 'created_at',
        sortField: 'created_at',
        showInTable: true,
        type: 'datetime',
        sort: 2,
      },
      {
        label: 'Просмотр печати',
        field: 'pdf_export',
        sortField: 'pdf_export',
        showInTable: true,
        sort: 0,
      },
      {
        label: 'Создал',
        field: 'createdBy.data.name',
        sortField: 'created_by',
        showInTable: true,
        sort: 0,
      },
      {
        label: 'Статус',
        field: 'status',
        nameField: 'status',
        sortField: 'status_id',
        type: 'estimate_status',
        showInTable: true,
        sort: 0,
      },
    ]
  },
  mounted() {
    const width = window.innerWidth
    this.$store.commit('sidebars/changeLeftSidebar', width > 768)
    this.$store.commit('sidebars/changeRightSidebar', false)
  },
  computed: {
    quantityItems() {
      return this.$store.state.estimates.selectedItemsForDelete
    },
  },
  methods: {
    addEstimate() {
      this.$store.commit('estimates/itemsForDelete', [])
      this.newOrder = true
      this.currentOrder = {}
      this.isLoadingItem = true
      EstimatesService.addEstimate().then(r => {
        this.isLoadingItem = false
        window.open(r.data.data['google_drive_file_url'], '_blank')
        this.getEstimates(true, true)
      })
    },
    archiveItems() {
      this.$store.commit('sidebars/changeRightSidebar', false)
      EstimatesService.deleteEstimates().then(r => {
        this.$toast.success(r.data['message'])
        this.getEstimates(true, true)
      })
    },
    deleteEstimates() {
      this.$refs['table-container'].loadingDelete = true
      EstimatesService.deleteEstimates(true).then(r => {
        this.$toast.success(r.data['message'])
        this.$refs['table-container'].loadingDelete = false
        this.$refs['table-container'].showDeleteModal = false
        this.getEstimates(true, true)
        this.$store.commit('estimates/resetSelectedRows')
      })
    },
    returnFromArchive() {
      EstimatesService.archiveItems().then(r => {
        this.$toast.success(r.data['message'])
        this.getEstimates(true, true)
      })
    },
    copyEstimate() {
      this.isLoadingCopyItem = true
      EstimatesService.copyEstimate(this.quantityItems[0]).then(r => {
        this.isLoadingCopyItem = false
        window.open(r.data.data['google_drive_file_url'], '_blank')
        this.getEstimates(true, true)
        this.$store.commit('estimates/resetSelectedRows')
      })
    },
    getEstimates(preloader = true, filter = false) {
      preloader ? (this.isLoadingItems = true) : (this.loadMoreData.loading = true)

      if (filter) this.nextPage = 1

      EstimatesService.getEstimates(this.nextPage).then(r => {
        store.commit('estimates/changePagination', r.data)
        const {total_pages, current_page} = r.data.meta.pagination
        this.loadMoreData.showButton = current_page < total_pages
        if (this.nextPage === 1) {
          this.estimates = r.data.data
        } else {
          this.estimates = [...this.estimates, ...r.data.data]
        }
        if (current_page < total_pages) this.nextPage++

        preloader ? (this.isLoadingItems = false) : (this.loadMoreData.loading = false)
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>